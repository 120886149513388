import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
import CircularProgress from '@mui/material/CircularProgress';
import QrReader from 'react-qr-scanner';
import QRCodeScannerIcon from '@mui/icons-material/PhotoCamera';
import DownloadIcon from '@mui/icons-material/CloudDownload';

interface AppProps {
    onSubmit: (data: any) => void;
}

const App: React.FC<AppProps> = ({ onSubmit }) => {
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [hash, setHash] = useState<string | null>(null);
    const [prompt, setPrompt] = useState<string | null>(null);
    const [selectedDate, setSelectedDate] = useState<string>(new Date().toISOString().slice(0, 7)); // Monat und Jahr im Format YYYY-MM
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [emailOrWalletID, setEmailOrWalletID] = useState<string>('');
    const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
    const [isMinting, setIsMinting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [email, setEmail] = useState('');
    const [wallet, setWallet] = useState('');
    const [selectedCategories, setSelectedCategories] = useState({
        politik: false,
        kunst: false,
        musik: false,
        film: false,
        sport: false,
        frei: ''
    });

    // Define type for format option
    type FormatOption = "bild" | "collage";

    // Initial state as a single string
    const [selectedFormat, setSelectedFormat] = useState("collage");

/*     enum StilOption {
        Zeitstil = "zeitstil",
        PopArt = "popart",
        Comic = "comic",
        Surrealismus = "surrealismus",
        Impressionismus = "impressionismus",
        Kubismus = "kubismus",
        Expressionismus = "expressionismus",
        Minimalismus = "minimalismus",
        Fotorealismus = "fotorealismus"
    } */

    type StilOption = "zeitstil" | "popart" | "comic" | "surrealismus" | "impressionismus" | "kubismus" | "expressionismus" | "minimalismus" | "fotorealismus" | "kinderkunst";

    const styles: { label: string; value: StilOption }[] = [
        { label: "Zeitstil", value: "zeitstil" },
        { label: "Pop Art", value: "popart" },
        { label: "Comic", value: "comic" },
        { label: "Surrealismus", value: "surrealismus" },
        { label: "Impressionismus", value: "impressionismus" },
        { label: "Kubismus", value: "kubismus" },
        { label: "Expressionismus", value: "expressionismus" },
        { label: "Minimalismus", value: "minimalismus" },
        { label: "Fotorealismus", value: "fotorealismus" },
        { label: "Kinderkunst", value: "kinderkunst" },
    ];

    // Initial state as a single string
    const [selectedStil, setSelectedStil] = useState("zeitstil");

    const isValidEthereumAddress = (input: string) => {
    return /^0x[a-fA-F0-9]{40}$/.test(input);
};

    const isValidEmail = (input: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
};

    const downloadImage = (url: string) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'zeitgeist-image.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleScan = (data: { text: string }) => {
        if (data && data.text) {
            const walletID = data.text.startsWith('ethereum:') ? data.text.replace('ethereum:', '') : data.text;
            setEmailOrWalletID(walletID);
            setIsScannerOpen(false); // Schließen des Scanners
        } else {
            console.log('Kein gültiger QR-Code gefunden.');
        }
    };

    const handleError = (err: any) => {
        console.error(err);
        setHasError(true);
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = e.target;
        if (name === 'frei') {
            setSelectedCategories(prev => ({ ...prev, frei: value }));
        } else {
            setSelectedCategories(prev => ({ ...prev, [name]: checked }));
        }
    };


    // Update handler to set `selectedFormat` directly
    const handleFormatChange = (format:FormatOption) => {
        setSelectedFormat(format);
    };


    // Update handler to set `selectedStil` directly
    const handleStilChange = (stil:StilOption) => {
        setSelectedStil(stil);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setHasError(false);

        const MonthYear = new Date(selectedDate).toLocaleString('de-DE', {
            month: 'long',
            year: 'numeric'
        });

        const categories = Object.keys(selectedCategories)
            .filter(key => selectedCategories[key as keyof typeof selectedCategories] && key !== 'frei')
            .map(key => key.charAt(0).toUpperCase() + key.slice(1))
            .join(', ');

        const format = Object.keys(selectedFormat)
            .filter(key => selectedFormat[key as keyof typeof selectedFormat] && key !== 'frei')
            .map(key => key.charAt(0).toUpperCase() + key.slice(1))
            .join(', ');


        const freeInput = selectedCategories.frei ? `, ${selectedCategories.frei}` : '';

        /*
        const completePrompt = `Erstelle eine Collage, die wichtige Ereignisse für die Zeit ${MonthYear} darstellt. 
        Die Collage soll folgende Themen beinhalten: ${categories} ${freeInput}  Die Collage soll im Pop Art Comic Stil gestaltet sein und die 
        jeweiligen Ereignisse möglichst gut repräsentieren. Die Collage darf auf keinen Fall politische Symbole enthalten! `;
         */

        /*
        const completePrompt = `Erstelle eine Collage um die Ereignisse im ${MonthYear} möglichst prägnant zu illustrieren.
        Suche dazu nach den wichtigsten Ereignissen in diesem Monat und dieser Jahreszeit.
        Fokussiere auf die Themen ${categories} ${freeInput}.
        Wenn der Inhalt gegen die Content Richtlinien verstößt, dann entferne diese Inhalte und suche nach anderen Inhalten so lange bis keine Content Violation mehr existiert! 
        Erzeuge auf alle Fälle eine Collage.`;
        */

        /*
        const completePrompt = `Erstelle eine Collage, die historische Ereignisse darstellt, die im ${MonthYear} stattfanden. 
        Lege den Fokus auf wichtige Ereignisse in den Bereichen ${categories} ${freeInput} in Deutschland. 
        Verwende abstrakte oder symbolische Darstellungen (z. B. Vintage-Zeitungen, ikonische Gebäude, Erfindungen, Landkarten, stilisierte Kalender, Natursymbole).
        Zeige keine spezifischen Personen oder explizite politische Ereignisse. 
        Gestalte die Collage mit einem Gefühl dieser Zeit und einem Mix aus historischen und kulturellen Symbolen, die die Essenz jedes Ereignisses visuell einfangen.
        `;
        */

        const completePrompt = `Erstelle  ${selectedFormat} für ${MonthYear} zu den Themen ${categories}, ${freeInput}.`;

        try {
            const response = await axios.post('/api/generate-image', {
                prompt: completePrompt,
                format: selectedFormat,
                date: MonthYear,
                topics: categories,
                api_key: "4711",
                stil: selectedStil,
                freeInput: freeInput,
                samplingTemperature: temp,
            });

            setImageUrl(response.data.url);
            setHash(response.data.hash);
            setPrompt(response.data.prompt);
        } catch (error: any) {
            console.error('Fehler beim Generieren des Bildes:', error);
            setHasError(true);
            alert(error.response?.data?.message || 'Fehler beim Generieren des Bildes.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleMintNFT = async () => {
        if (!imageUrl) return;

    // Überprüfen, ob es sich um eine gültige Ethereum-Adresse oder eine E-Mail handelt
    if (!isValidEthereumAddress(emailOrWalletID) && !isValidEmail(emailOrWalletID)) {
        alert('Bitte geben Sie eine gültige E-Mail-Adresse oder Ethereum Wallet ID ein.');
        return;
    }


    setIsMinting(true);
    let dateTime = new Date();

    let formattedDateTime = new Intl.DateTimeFormat('de-DE', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }).format(dateTime);

    const payload = {
        nameAttribute: `Zeitgeist NFT - ${new Date(selectedDate).toLocaleDateString('de-DE', { month: 'long', year: 'numeric' })}`,
        stringAttribute: [
            {
                trait_type: "NFT_Type",
                value: "Zeitgeist"
            },
            {
                trait_type: "Created",
                value: `${formattedDateTime}`
            },
            {
                trait_type: "Created by",
                value: "Dall-E"
            },
            {
                trait_type: "Originator",
                value: "Blockchain Reallabor"
            },
            {
                trait_type: "Event",
                value: "ZRR: Perspektiven"
            },
            {
                trait_type: "Hash",
                value: `${hash}`
            },
            {
                trait_type: "Prompt",
                value: `${prompt}`
            },
        ],
        descriptionAttribute: "A unique Zeitgeist NFT illustrating history.",
        wallet: emailOrWalletID,
        ipfsLink: imageUrl
    };

    try {
        const response = await axios.post('/api/mint-nft-to', payload, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        alert(`NFT minted successfully! Transaction Hash: ${response.data.transactionHash}, Token ID: ${response.data.tokenId}`);
window.location.reload();
    } catch (error: any) {
        console.error('Error minting the NFT:', error.message);
        setHasError(true);
        alert(`Error minting the NFT: ${error.message}`);
    } finally {
        setIsMinting(false);
    }
};

    const handleReload = () => {
        window.location.reload();
    };


        const [temp, setValue] = useState<number>(0.9); // Default value is 1
    
        const handleTempChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(parseFloat(event.target.value)); // Parse to a float to handle decimals
        };


    return (
        <div className="app-container">
            <h1>Zeitgeist Generator</h1>

            {!imageUrl ? (
                <form onSubmit={handleSubmit} className="form">
                    <div className="input-container">
                        <div>
                            <label htmlFor="date" className="label-date">Wähle Monat und Jahr:</label>
                            <input
                                type="month" // Hier den Typ auf "month" ändern
                                id="date"
                                className="date-picker-input"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                        {/* Auswahl Bild Collage */}
                        <div className="checkbox-container">
                            <h2>Wähle dein Bildformat:</h2>
                            <label>
                                <input
                                    type="checkbox"
                                    name="format"
                                    value="bild"
                                    checked={selectedFormat === "bild"}
                                    onChange={() => handleFormatChange("bild")}
                                />
                                Bild
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="format"
                                    value="collage"
                                    checked={selectedFormat === "collage"}
                                    onChange={() => handleFormatChange("collage")}
                                />
                                Collage
                            </label>
                        </div>

                    {/* Auswahl diverser Stilarten */}
                    <div className="checkbox-grid-container">
                        <h2>Wähle deine Stil-Epoche:</h2>
                        <div className="style-checkboxes">
                            {styles.map((style) => (
                                <label key={style.value} className="style-label">
                                    <input
                                        type="checkbox"
                                        name="stil"
                                        checked={selectedStil === style.value}
                                        onChange={() => handleStilChange(style.value)}
                                    />
                                    {style.label}
                                </label>
                            ))}
                        </div>
                    </div>

                    {/* Auswahl diverser Themen */}
                    <div className="checkbox-container">
                        <h2>Themen:</h2>
                        <label>
                            <input type="checkbox" name="politik" checked={selectedCategories.politik} onChange={handleCheckboxChange} />
                            Politik
                        </label>
                        <label>
                            <input type="checkbox" name="kunst" checked={selectedCategories.kunst} onChange={handleCheckboxChange} />
                            Kunst
                        </label>
                        <label>
                            <input type="checkbox" name="musik" checked={selectedCategories.musik} onChange={handleCheckboxChange} />
                            Musik
                        </label>
                        <label>
                            <input type="checkbox" name="film" checked={selectedCategories.film} onChange={handleCheckboxChange} />
                            Film
                        </label>
                        <label>
                            <input type="checkbox" name="sport" checked={selectedCategories.sport} onChange={handleCheckboxChange} />
                            Sport
                        </label>

                        {/* Auswahl Temperatur */}

                        <div style={{ marginTop: "20px" }}>
                            <label>
                                <span>Temperatur: {temp.toFixed(1)}</span> {/* Display value with one decimal places */}
                                <input
                                    type="range"
                                    min="0"          // Theoretisch kann die Temperatur zwischen 0 und 2 liegen   
                                    max="1"          // Werte über 1 machen keinen Sinn und führe zu unsinnigen Antworten
                                    step="0.1" 
                                    value={temp}
                                    onChange={handleTempChange}
                                    style={{ width: "100%", marginTop: "10px" }}
                                />
                            </label>
                        </div>

                        <div>
                            <label htmlFor="frei">Freie Eingabe:</label>
                            <input
                                type="text"
                                id="frei"
                                name="frei"
                                value={selectedCategories.frei}
                                onChange={(e) => handleCheckboxChange(e)}
                            />
                        </div>

                    </div>
                    <button type="submit">Bild generieren</button>
                </form>
            ) : (
                <>
                    <div className="email-input-container">
                        <input
                            type="text"
                            id="emailOrWalletID"
                            className="email-input"
                            value={emailOrWalletID}
                            onChange={(e) => setEmailOrWalletID(e.target.value)}
                            required
                            placeholder="E-Mail oder Wallet ID"
                        />
                        <button
                            type="button"
                            onClick={() => setIsScannerOpen(true)}
                            className="scanner-button"
                        >
                            <QRCodeScannerIcon />
                        </button>
                    </div>

                    <button onClick={handleMintNFT} disabled={isMinting}>
                        {isMinting ? 'Minting...' : 'Mint NFT'}
                    </button>

                    {isScannerOpen && (
                        <div className="qr-scanner-container">
                            <QrReader
                                delay={300}
                                onError={handleError}
                                onScan={handleScan}
                                style={{ width: '100%' }}
                            />
                        </div>
                    )}

                    <div className="image-container">
                        <h2>Dein Zeitgeist NFT</h2>
                        <h3>Datum: {new Date(selectedDate).toLocaleDateString('de-DE', { month: 'long', year: 'numeric' })}</h3>
                        <img src={imageUrl} alt="Generiertes" className="generated-image" />
                    </div>
                    <div className="download-container">
                        <button onClick={() => downloadImage(imageUrl)} className="download-button">
                            <DownloadIcon />
                        </button>
                    </div>
                </>
            )}

            {isLoading && (
                <div className="loading">
                    <CircularProgress />
                    <p>Bild wird generiert...</p>
                </div>
            )}

            {imageUrl && hasError && errorMessage && (
                <div className="error-message">
                    <p>{errorMessage}</p> {/* Zeigt die dynamische Fehlermeldung an */}

                    <button onClick={handleReload} className="reload-button">Seite neu laden</button>
                </div>
            )}

            {imageUrl && !hasError && (
                <>
                    <button onClick={handleReload} className="reload-button">Seite neu laden</button>
                </>
            )}
        </div>
    );
};

export default App;
